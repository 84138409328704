import React, {useContext, useEffect, useRef, useState} from 'react';
import {FetchContext} from 'App/Strapi/FetchContext';
import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import {toast} from 'react-toastify';
import Select from 'react-select'
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import Grid from "UI/App/Components/Grid/Grid";
import Table from "UI/App/Components/Table/Table";

export default function ImportCatalog() {
    const {authAxios} = useContext(FetchContext);

    const [importSetting, setImportSetting] = useState(1);
    const [csvArray, setCsvArray] = useState([]);
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [validationData, setValidationData] = useState({});

    const [importIssues, setImportIssues] = useState([]);

    let fileReader = new FileReader();
    const fileInput = useRef();
    const [file, setFile] = useState(null);

    // csv can have different types of separators, these are the ones most commonly used
    const csvSeparators = [
        ",",
        ";",
        "\t",
        "|"
    ];
    const options = [
        {value: 0, label: 'Pas prijzen aan'},
        {value: 1, label: 'Lijst importeren'},
    ];

    // requirements to check for BEFORE we try to update/create entries
    // this gets used by the verify csv function to detect if we have all the parts
    const requirementsPerOption = [
        {
            value: 0,
            required: [
                "Id",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs"
            ],
            // the field strapi wil update on
            strapiUpdateOn: "id",
            noneEmptyFields: [
                "Id",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs",
            ],
        },
        {
            value: 1, // value of the select
            required: [
                "Categorie",
                "Workflow / artikelnummers",
                "Artikelnummer leverancier",
                "Calculatie omschrijving",
                "Offerte omschrijving NL",
                "Offerte omschrijving UK",
                "Lengte in meters",
                "Breedte in meters",
                "Hoogte in meters",
                "Dikte in millimeters",
                "Gewicht in grammen",
                "Min. aantal per bestelling",
                "Looprichting",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs",
                "aantal per eenheid",
                "eenheid",
                "Coated",
                "Datum laatste prijs",
                "Actief",
                "Order eenheid"
            ],
            // the field strapi wil update on
            strapiUpdateOn: "id",
            // fields that cant be empty
            noneEmptyFields: [
                "aantal per eenheid",
                "Verkoopprijs",
                "Categorie",
                "Offerte omschrijving NL",
                "Offerte omschrijving UK",
            ],
            // fields that are only allowed to have a value from a pre defined list
            enumFields: {
                'eenheid': [
                    "kilogram",
                    "meter",
                    "strekkende meter",
                    "vierkante meter",
                    "enveloppen",
                    "vel",
                    "stuks",
                    "dozen",
                    "liter",
                    "rol"
                ],
                'Order eenheid': [
                    "rol",
                    "pallet",
                    "doos",
                    "dozen",
                    "meter",
                    "liter",
                    "vel"
                ],
                'Looprichting': [
                    "LL",
                    "BL"
                ],
                'Actief': [
                    "Ja",
                    "Nee"
                ]
            }
        }
    ];

    // Gets raw data from CSV
    async function gatherDataFromCSV(CSVFile) {
        return new Promise(function (resolve, reject) {

            // Gather data from csv
            let csvRows = [];
            if (CSVFile !== undefined) {
                fileReader.onload = function (event) {
                    const text = event.target.result;
                    csvRows = csvToArray(text);
                };
                fileReader.readAsText(CSVFile);
            }

            // Give filereader time to do its thing
            setTimeout(function () {
                resolve(csvRows);
            }, 1000);
        });
    }

    // Resets import
    function resetImport() {
        setStep(1);
        setCsvArray([]);
        setIsLoading(false);
        setImportIssues([]);
        setValidationData([]);
        fileReader = new FileReader();
        setFile(null);
        fileInput.current.value = "";
    }

    // Check for changes
    async function checkForChanges() {
        setIsLoading(true);

        // Gather data from file
        const csvData = await gatherDataFromCSV(file);

        // Validate file on missing and or wrong content
        const valid = await validateImportForType(csvData);
        let translatedArr = translateFields(csvData);

        if (!valid) {
            setIsLoading(false);
            return;
        }

        const data = await authAxios.put('/logistics/materials/import/?validate=true', {
            data: translatedArr,
        }).then((data) => {
            return data;
        }).catch((exception) => {

            console.error(exception);
        });

        setValidationData(data.data);
        setIsLoading(false)
    }

    // Runs import
    async function importCsv(e) {
        setIsLoading(true);
        let toastId = toast.loading("Materiaallijst importeren...");

        let translatedArr = translateFields(csvArray);

        // If price update remove all unused headers
        if (importSetting === 0) {

            // filter out the fields we need for the update
            let requiredFieldNames = ['id', 'purchasePrice', 'regularPrice', 'salePrice'];
            translatedArr = translatedArr.map(function (material) {
                return Object.keys(material)
                    .filter(fieldName => requiredFieldNames.includes(fieldName))
                    .reduce((updatedMaterial, fieldName) => {
                        updatedMaterial[fieldName] = material[fieldName];
                        return updatedMaterial;
                    }, {});
            })
        }

        // Put for price update or material update
        await authAxios.put(importSetting === 0 ? '/logistics/materials/update' : '/logistics/materials/import', {
            data: translatedArr,
        }).then(({data: {data}}) => {
            toast.update(toastId, {render: "Succesvol geïmporteerd", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true});
            closePopup();
        }).catch((exception) => {
            toast.update(toastId, {render: "Importeren mislukt", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true});

            console.error(exception);
        });

        setIsLoading(false);
        resetImport();
    };

    // Fixes naming in csv data
    function translateFields(array) {

        function zeroIfEmpty(value) {
            return isNaN(Number(value?.replaceAll(",", '.'))) ? 0 : Number(value?.replaceAll(",", '.'));
        }

        function nullIfEmpty(value) {
            return value === "" ? null : value;
        }

        function parseDate(date) {

            if (!/[0-9]{1,2}[/\\-][0-9]{1,2}[/\\-][0-9]{4}/.test(date)) {
                return "1970-01-01";
            }

            const [day, month, year] = date.split(/[/\\-]/);
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        }

        return array.map((item) => ({
            id: nullIfEmpty(item["Id"]),
            category: item["Categorie"],
            internalSKU: item["Workflow / artikelnummers"],
            supplierSKU: nullIfEmpty(item["Artikelnummer leverancier"]),
            calculationDescription: item["Calculatie omschrijving"],
            quotationNameNL: item["Offerte omschrijving NL"],
            quotationNameEN: item["Offerte omschrijving UK"],
            lengthInMeters: zeroIfEmpty(item["Lengte in meters"]),
            widthInMeters: zeroIfEmpty(item["Breedte in meters"]),
            heightInMeters: zeroIfEmpty(item["Hoogte in meters"]),
            thicknessInMM: zeroIfEmpty(item["Dikte in millimeters"]),
            weightInGram: zeroIfEmpty(item["Gewicht in grammen"]),
            unitsPerOrder: zeroIfEmpty(item["Min. aantal per bestelling"]),
            unitsPerOrderName: nullIfEmpty(item["Order eenheid"]),
            runningDirection: nullIfEmpty(item["Looprichting"]),
            purchasePrice: zeroIfEmpty(item["Inkoopprijs"]),
            regularPrice: zeroIfEmpty(item["Actuele inkoop"]),
            salePrice: zeroIfEmpty(item["Verkoopprijs"]),
            countPerUnit: zeroIfEmpty(item["aantal per eenheid"]),
            unit: nullIfEmpty(item["eenheid"]),
            coated: item["Coated"]?.toLowerCase() === "ja",
            lastPriceUpdate: parseDate(item["Datum laatste prijs"]),
            active: item["Actief"]?.toLowerCase() === "ja",
        }));
    }

    // Converts CSV string to array
    function csvToArray(input) {
        let identifier = input.slice(0, input.indexOf("\n")).replace(/[\r\n"]/gm, '');
        let splittingCharacter = null;

        // try to separate on know separators and find id.
        for (let separator of csvSeparators) {
            if (identifier.split(separator).includes("Id")) {
                splittingCharacter = separator;
            }
        }

        // no notify the user that something is wrong with the given csv
        if (splittingCharacter === null) {
            toast.error("De csv is in een onbekend indeling, of heeft geen Id veld")
            return
        }

        // split on working separator
        identifier = identifier.split(splittingCharacter)
        let rows = input.slice(input.indexOf("\n")).replace(/\n/gm, '').split("\r");

        // prepare rows with header names
        rows = rows.map(function (row) {

            if (row === "" || row === undefined) {
                return;
            }
            ;
            let values = row.replace(/"/gm, '').split(splittingCharacter);

            return identifier.reduce((obj, header, index) => {
                obj[header] = values[index];
                return obj
            }, {});

        })
        // filter out empty entries
        rows = rows.filter(function (row) {
            if (row !== undefined) {
                return row;
            }
        });

        // set header, and rows
        setCsvArray(rows);
        return rows;
    }

    // Validates single CSV data row
    function validateRow(row) {
        const rowIssues = {
            missingHeaders: [],
            missingContent: [],
            wrongContent: []
        };

        // Check for missing headers
        for (const requiredHeader of requirementsPerOption[importSetting].required) {
            if (row[requiredHeader] === undefined) {
                rowIssues.missingHeaders.push(requiredHeader);
            }
        }

        for (let [enumKey, enumType] of Object.entries(requirementsPerOption[importSetting].enumFields)) {

            // If undefined header is missing and caught by the missing headers loop
            if (row[enumKey] !== undefined) {

                // Check if ENUM matches ENUM in settings
                if (requirementsPerOption[importSetting].enumFields[enumKey].find((m) => m === row[enumKey]) === undefined) {
                    rowIssues.wrongContent.push({
                        header: enumKey,
                        content: row[enumKey]
                    });
                }
            }
        }

        // Check fields that cant be empty
        for (const notNullableField of requirementsPerOption[importSetting].noneEmptyFields) {
            // If field non existing missing headers are caught by the missing headers loop
            if (row[notNullableField] === undefined) continue;

            if (row[notNullableField] === '') {
                rowIssues.missingContent.push({
                    header: notNullableField,
                    content: 'empty string'
                })
            }
        }

        return rowIssues;
    }

    async function validateImportForType(data) {
        let importIssues = [];
        for (const rowKey in data) {
            const row = data[rowKey];
            const rowIssues = validateRow(row);

            if (rowIssues.wrongContent.length > 0 || rowIssues.missingContent.length > 0 || rowIssues.missingHeaders.length > 0) {
                importIssues[rowKey] = rowIssues
            }
        }

        if (importIssues.length > 0) {
            setImportIssues(importIssues);
            return false;
        }

        return true;
    }

    // return popup
    return (
        <Popup title='Import een csv' popupID='import-catalog'>
            <SpinnerOverlay visible={isLoading}>
                {/* Step 1 uploading import file */}
                <span style={{display: step === 1 ? 'block' : 'none'}}>
                        {/*<form>*/}
                    <input
                        type={"file"}
                        id={"csvFileInput"}
                        ref={fileInput}
                        accept={".csv"}
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                        <Select options={options} onChange={(e) => setImportSetting(e.value)} defaultValue={{value: 1, label: 'Lijst importeren'}}/>

                    <Grid columns={2}>
                        <span></span>
                        <button
                            disabled={file === null}
                            type={"button"}
                            onClick={() => {
                                setStep(2)
                                checkForChanges();
                            }}>
                            Volgende
                        </button>
                    </Grid>
                </span>

                {/* Step two validating import file */}
                <span style={{display: step === 2 ? 'block' : 'none'}}>
                   {importIssues.length > 0 ? <Grid columns={1}>
                       {importIssues.map((issues, rowKey) => {
                           let issueString = `Op regel ${rowKey + 2} `

                           for (const missingContent of issues.missingContent) {
                               issueString += ` missende data onder ${missingContent.header}`;
                           }

                           for (const wrongContent of issues.wrongContent) {
                               issueString += ` foute data onder ${wrongContent.header} : ${wrongContent.content}`;
                           }

                           for (const missingHeader of issues.missingHeaders) {
                               issueString += ` missende header ${missingHeader}`;
                           }

                           return <span>{issueString}</span>
                       })}
                   </Grid> : <Grid columns={1} gap='10px'>
                        <span>
                            De volgende acties worden uitgevoerd tijdens de import.
                        </span>
                           <span>
                             <strong>Updates: </strong> {validationData?.updates?.length}
                        </span>
                           <span>
                            <strong>Nieuwe materialen: </strong> {validationData?.created?.length}
                        </span>
                   </Grid>
                   }

                    <Grid columns={2}>
                        <button onClick={() => resetImport()}>
                            Reset import
                        </button>
                        <button disabled={importIssues.length > 0} onClick={() => importCsv()}>
                            Importeren
                        </button>
                    </Grid>
                </span>
            </SpinnerOverlay>
        </Popup>
    )
}

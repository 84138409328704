import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {useNavigate} from "react-router-dom";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Table from "UI/App/Components/Table/Table";
import Icon from "UI/App/Components/Icon/Icon";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import CreateWareHouse from "UI/App/Partials/Content/Warehouse/CreateWareHouse";
import HasRole from "UI/App/Components/Auth/HasRole";
import {BooleanControlled} from "UI/App/Components/Form/Boolean";
import { exportObject } from "App/Util/exportObject";
import { formatCurrency } from "App/Util/format";
import { AuthContext } from 'App/Strapi/AuthProvider';

export default function WareHouses() {
    const {authAxios} = useContext(FetchContext);
    const authData = useContext(AuthContext);
    const navigate = useNavigate();

    const [filterWarehouses, setFilterWareHouses] = useState(true);
    const [wareHouseTableData, setWareHouseTableData] = useState([]);

    const {
        paging,
        filtering,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        storageKey: 'warehouse',
        searchSettings: {
            enabled: true,
            strapiFields: ['name', 'location']
        },
        htmlElements: [
            <HasRole roles={['admin']}>
                <span className='pagination__filter addItem' key='link--add'>
                    <span style={{ cursor: "pointer" }} onClick={(e) => {
                        //openPopup("exportMaterials")
                        getExportMaterials();
                    }} className='btn btn--add btn--black btn--icon-right'>Exporteer voorraad <Icon name='arrow-down' /></span>
                </span>
            </HasRole>,
            <HasRole roles={['admin']}>
                <span className='pagination__filter addItem' key='link--add'>
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    openPopup("addWarehouse")
                }} className='btn btn--add btn--icon-right'>Toevoegen <Icon name='plus'/></span>
            </span>
            </HasRole>,
        ],
    });

    const query = stringify({
        sort: ['id:desc']
    }, { encodeValuesOnly: true});

    useEffect(() => {
        if(filterWarehouses){
            getWareHouses();
        }else{
            getStorages();
        }
    }, [filterQuery,filterWarehouses]);

    function getStorages() {
        authAxios
            .get(`/storages`)
            .then(({data}) => {
                const tableData = [];

                // loop through all the records
                for (const [index, wareHouse] of Object.entries(data.data)) {

                    // add a row for the warehouse
                    tableData.push({
                        attributes: {
                            onClick: (e) => {

                                navigate(`/warehouses/${wareHouse.id}/storage`);
                            }
                        },
                        data: [
                            wareHouse.name,
                            wareHouse.location,
                        ]
                    });
                }

                setWareHouseTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });
    }

    function getWareHouses(){
        authAxios
            .get(`/logistics/warehouses?${query}&${filterQuery}`)
            .then(({data}) => {
                const tableData = [];

                // loop through all the records
                for (const [index, wareHouse] of Object.entries(data.data)) {

                    // add a row for the warehouse
                    tableData.push({
                        attributes: {
                            onClick: (e) => {

                                navigate(`/warehouses/${wareHouse.id}`);
                            }
                        },
                        data: [
                            wareHouse.name,
                            wareHouse.location,
                        ]
                    });
                }

                setWareHouseTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });
    }

    function getExportMaterials() {
        const query = stringify({
            populate: {
                stock: true
            }
        }, { encodeValuesOnly: true });
        authAxios
            .get(`/logistics/materials/all?${query}`)
            .then(({ data }) => {
                // create list of all materials to export
                let exportList = []

                for (let material of data) {
                    let totalAmount = 0;
                    // calculate pcs.
                    let pricePerPiece = material.purchasePrice / material.countPerUnit;

                    // check all know stocks for total material count
                    for (let stock of material.stock) {
                        totalAmount += stock.total
                    }


                    // push formated findings to an array
                    exportList.push({
                        "Artikelnummer klant": material?.supplierSKU ?? "",
                        "Interne artikelnummer": material?.internalSKU ?? "",
                        "Aantal": totalAmount,
                        "Eenheid": material.unit ?? "onbekend",
                        "Stuk prijs": `${formatCurrency(pricePerPiece)}`,
                        "Totale waarde voorraad": ` ${formatCurrency(pricePerPiece * totalAmount)}`
                    })

                }

                // export the formated list
                exportObject(exportList, {
                    filename: `actuele_voorraad-${new Date().toLocaleDateString("nl-NL", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                    fieldSeparator: ';',
                    decimalSeparator: 'locale',
                    useKeysAsHeaders: true,
                    showColumnHeaders: true,
                    useBom: true,
                }, authAxios, authData, "export.warehouses");
            })
    }

    const doneCreating = () => {
        closePopup('addWarehouse');
        getWareHouses();
    }

    return (
        <>
            <Popup
                popupID='addWarehouse'
                title={'Magazijn toevoegen'}
                overflow={true}
            >
                <CreateWareHouse closePopup={doneCreating}/>
            </Popup>
            {filtering}
            <Table
                headers={['Naam', 'Locatie']}
                data={wareHouseTableData}
                padding={'s'}
            />
            {paging}
        </>
    );
}

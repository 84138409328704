import {usePagination} from "UI/App/Components/Pagination/Pagination";
import Table from "../../../../Components/Table/Table";
import {useContext, useEffect, useState} from "react";
import Button from "UI/App/Components/Button/Button";
import {FetchContext} from "App/Strapi/FetchContext";
import {useNavigate} from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import capitalize from "lodash/capitalize";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Popup, {openPopup,closePopup} from "../../../../Components/Popup/Popup";
import CreateOrder from "./CreateOrder";
import {formatDateWithoutTime} from "../../../../../../App/Util/format";
import HasRole from "UI/App/Components/Auth/HasRole";
import IF from "UI/App/Components/Conditional/IF";
import User from "App/Strapi/User";
import CopyOrder from "UI/App/Partials/Content/CRM/Orders/CopyOrder";
import { exportObject } from "App/Util/exportObject";
import { AuthContext } from 'App/Strapi/AuthProvider';

export default function Orders() {
    const [tableData, setTableData] = useState([]);
    const [orderIdToCopy, setOrderIdToCopy] = useState(null);
    const {authAxios} = useContext(FetchContext);
    const authData = useContext(AuthContext);
    const navigate = useNavigate();

    // headers={       ['',     'Nummer',  'Klant',    'Omschrijving',     'Leverdatum',   'Aanmaakdatum',     'Status',   '']}

    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'id',
            direction: 'desc',
            defaultDirection: 'desc'
        },
        fields: [
            {},
            {
                name: 'Nummer', field: 'number', sortable: true,
            },
            {
                name: 'Klant', field: 'company.name', sortable: false,
            },
            {
                name: 'Omschrijving', field: 'description', sortable: false,
            },
            {
                name: 'Aanmaakdatum', field: 'createdAt', sortable: false,
            },
            {
                name: 'Leverdatum', field: 'deliveryDate', sortable: true,
            },
            {
                name: 'Status', field: 'status', sortable: false
            },
            {}
        ]
    });

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded,
    } = usePagination({
        storageKey: 'order',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'number',
                '[quoteConfirmation][quote][company][name][description]',
                '[company][name]',
                'description'
            ]
        },
        htmlElements: [
            <span className='pagination__filter addItem' key='link--add'>
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    openPopup("addOrder")
                }} className='btn btn--add btn--icon-right'>Toevoegen <Icon name='plus'/></span>
            </span>,
            <Button onClick={(e) => ExportOrders(e, filterQuery)} className='btn btn--icon-right btn--black'>Exporteren <Icon name='download' onClick={(e) => e.stopPropagation()} /></Button>,
        ],
        filters: [
            {
                name: "status",
                type: "select",
                options: [
                    {value: "*", name: "Alle"},
                    {value: "Binnengekomen", name: "Binnengekomen"},
                    { value: "Prepress", name: "Prepress" },
                    { value: "Productie", name: "Productie" },
                    {value: "Derden", name: "Derden"},
                    {value: "Verzending", name: "Verzending"},
                    { value: "Factureren", name: "Factureren" },
                    {value: "Afgerond", name: "Afgerond"},
                    { value: "Klacht", name: "Klacht" },
                    {value: "Afgeboekt", name: "Afgeboekt"},
                    {value: "Reservering", name: "Reservering"},
                    { value: "Niet in behandeling genomen", name: "Niet in behandeling genomen" },
                ],
            }, {
                name: 'owner',
                type: 'select',
                options: [
                    { name: "Alle bedrijven", value: '*' },
                    { name: "HJMG", value: "hjmg" },
                    { name: "Pretore", value: "pretore" },
                ],
                callbackFunction: callbackFilter
            }
        ],
    });

    useEffect(() => {
        let roleSpecificFilter = {}
        if (User.hasRole(['Productie medewerker'])) {
            roleSpecificFilter = {
                status: {
                    $in: ["binnengekomen", "productie", "verzending", "derden", "klacht"]
                }
            }
        }

        const params = stringify({
            sort: {
                [tableColumns.sort.column]: tableColumns.sort.direction
            },
            pagination: {
                page: currentPage,
                pageSize: resultsPerPage
            },
            populate: {
                company: {
                    fields: ["name"]
                },
                contactPerson: true,
                calculation: {
                    fields: [
                        'customerReference',
                        'owner'
                    ],
                },
                quoteConfirmation: {
                    fields: [
                        'number'
                    ],
                    populate: {
                        quote: {
                            populate: {
                                company: {
                                    fields: [
                                        'name'
                                    ]
                                }
                            },
                            fields: [
                                'number'
                            ]
                        },
                    }
                },
                project: {
                    fields: ['id'],
                    populate: [
                        'machinePlanItems'
                    ]
                },
                planning: {
                    fields: ['id'],
                }
            },
            fields: [
                'number',
                'status',
                'description',
                'deliveryDate',
                'createdAt'
            ],
            filters: {
                $and: [
                    {
                        id: {
                            $notIn: JSON.parse(process.env.REACT_APP_HIDDEN_ORDER_IDS)
                        }
                    },
                    roleSpecificFilter
                ]
            }
        })

        // get the companies using the built params
        authAxios.get(`/orders?${params}&${filterQuery}`)
            .then(({data}) => {
                const tableData = [];
                const statusses = [
                    'Binnengekomen',
                    'In proef',
                    'Bestand afgekeurd (door klant)',
                    'Bestand afgekeurd (door Pretore)',
                    'Prepress',
                    'Factureren',
                    'Productie',
                    'Derden',
                    'Verzending',
                    'Klacht',
                    'Afgerond',
                    'Afgeboekt',
                    'Reservering',
                    'Niet in behandeling genomen'
                ]

                // loop through all the records
                for (const order of data.data) {

                    // Skip order if order has no calculation
                    if (order?.calculation === null) continue;

                    let options = [];
                    for (const status of statusses) {
                        options.push(<option key={status}>{capitalize(status)}</option>)
                    }

                    // Get calculation from confirmation or order
                    let calculation = null;
                    if (order?.quoteConfirmation !== null && order?.quoteConfirmation !== undefined) {
                        calculation = order.quoteConfirmation.calculation;
                    } else if (order?.calculation !== null && order?.calculation !== undefined) {
                        calculation = order.calculation;
                    }

                    let reference = 'Leeg';
                    let calculationTemplate = 'Leeg';
                    let description = 'Leeg';
                    if (order.description !== null) {
                        description = order.description
                    }

                    if (calculation !== undefined ? (calculation?.customerReference !== null && calculation?.customerReference !== undefined) : false) {
                        reference = calculation.customerReference;
                    }

                    tableData.push({
                        attributes: {
                            onClick: () => {
                                navigate(`/orders/${order.id}`)
                            },
                            id: order.id
                        },
                        data: [
                            <div className='owner-indicator'>
                                <IF condition={typeof order?.calculation?.owner === 'string'}>
                                    <span className={`circle circle-${order?.calculation?.owner?.toLowerCase()}`}></span>
                                </IF>
                            </div>,
                            order.number,
                            order?.company?.name ?? '',
                            description,
                            formatDateWithoutTime(order.createdAt),
                            order.deliveryDate !== null ? formatDateWithoutTime(order.deliveryDate) : '',
                            <>
                                <HasRole roles={['admin', 'management', 'productie manager']}>
                                    <select defaultValue={capitalize(order?.status) ?? undefined} onClick={(e) => {
                                        e.stopPropagation()
                                        }} onChange={(e) => {
                                            updateStatus(e, order.id)
                                        }}>
                                    {options}
                                    </select>
                                </HasRole>
                                <HasRole roles={['productie medewerker']}>
                                    {order?.status}
                                </HasRole>
                            </>,
                            <div className={'table__action-buttons'}>
                                <Button type='button' data-title={'Kopiëren'} className={'btn btn--transparent'} onClick={(e) => {
                                    e.stopPropagation()
                                    setOrderIdToCopy(order.id)
                                    openPopup("copyOrder")
                                }}><Icon name={'copy'}/></Button>
                                <Button data-title={'Bekijken'} className={'btn btn--transparent'} to={`/orders/${order.id}`} onClick={(e) => e.stopPropagation()}><Icon name={'eye'}/></Button>
                                <IF condition={order?.calculation.owner !== null && order?.calculation.owner.toLowerCase() === 'pretore'}>
                                    <HasRole roles={['admin', 'management', 'productie manager']}>
                                        <Button
                                            data-title={'Planning'}
                                            className={'btn btn--transparent'}
                                            onClick={(e) => e.stopPropagation()} to={(!order.planning) ? `/planning/orders/${order.id}/create` : `/planning/orders/${order.id}`}
                                        >
                                            <Icon name={'calendar'} style={{
                                                fill: !order.planning && ['binnengekomen', 'prepress', 'productie'].includes(order.status) ? 'var(--text-error)' : 'revert-layer'
                                            }} />
                                        </Button>
                                    </HasRole>
                                </IF>
                            </div>
                        ]
                    })
                }
                setTableData(tableData);

                // Set pagination
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }, [currentPage, filterQuery, resultsPerPage, paginationStateLoaded, tableColumns])

    const updateStatus = (e, orderId) => {

        authAxios.put(`/orders/${orderId}`, {
            "data": {
                "status": e.target.value.toLowerCase()
            }
        }).then(({data}) => {
            toast.success(`Order: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van order.`);
        });
    }
    function callbackFilter(e) {

        // gaurd against no value or default value
        if (e?.currentValue === undefined || e?.currentValue === "*") return;
        // create the custom filter object
        let returnObject = {
            calculation: {
                owner: e.currentValue
            }
        }
        // return the custom object
        return returnObject;
    }

    function closeOrderCopy(){
        closePopup('copyOrder')
    }

    /**
     * Export orders
     */
    async function ExportOrders(e, filters) {
        let toastId = toast.loading("orders exporteren...");

        // fetch orders
        // initialize empty csv data array
        let csvData = [];

        // populate wanted data only
        const params = stringify({
            populate: {
                calculation: {
                    fields: [
                        'customerReference',
                    ],
                },
            },
            fields: [
                'number',
                'status',
                'description',
                'deliveryDate'
            ],
        })

        try {
            // fetch wanted data
            await authAxios.get(`/orders/all?${params}&${filters}`).then(({ data }) => {

                csvData = data.map((order) => {
                    // translate keys
                    return {
                        "Id": order.id ?? "",
                        "Order nummer": order.number ?? "",
                        "Order omschrijving": order?.description ?? "",
                        "Klantreferentie": order?.calculation?.customerReference ?? "",
                        "Status": order?.status ?? "",
                        "Afleverdatum": order?.deliveryDate ?? ""
                    }

                })

                // export the object as csv
                exportObject(csvData, {
                    filename: `OrderLijst_export-${new Date().toLocaleDateString("nl", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYYY
                    fieldSeparator: ';',
                    decimalSeparator: 'locale',
                    useKeysAsHeaders: true,
                    showColumnHeaders: true,
                    useBom: true,
                }, authAxios, authData, "export.orders")

                // update user of progress
                toast.update(
                    toastId, {
                    render: `Orders zijn succesvol geëxporteerd`,
                    type: "success",
                    isLoading: false,
                    autoClose: 2500
                });


            });

        } catch (error) {
            // tell user of an error
            toast.update(
                toastId, {
                render: `Orders kon niet geëxporteerd worden`,
                type: "error",
                isLoading: false,
                autoClose: 2500
            });


            process.env.NODE_ENV === 'development' && console.error(error);
        };
    }

    return (
        <div>
            <Popup
                overflow={true}
                popupID='copyOrder'
                title={'Order kopiëren'}
            >
                <CopyOrder
                    orderId={orderIdToCopy}
                    closePopup={() => closeOrderCopy()}
                />
            </Popup>
            <Popup
                overflow={true}
                popupID='addOrder'
                title={'Order toevoegen'}
            >
                <CreateOrder/>
            </Popup>
            {filtering}
            <Table
                tableColumns={tableColumns}
                setTableColumns={setTableColumns}
                headers={       ['',     'Nummer',  'Klant',    'Omschrijving',      'Aanmaakdatum',   'Leverdatum',      'Status',   '']}
                structure={{
                    500:        ['40px', '100%',    0,          0,                  0,              0,                  0,          '115px'],
                    750:        ['40px', '150px',   '100%',     0,                  0,              0,                  0,          '115px'],
                    800:        ['40px', '150px',   '100%',     0,                  0,              0,                  0,          '115px'],
                    1000:       ['40px', '150px',   '100%',     0,                  0,              0,                  '150px',    '115px'],
                    1350:       ['40px', '150px',   '250px',    '100%',             0,              0,                  '150px',    '115px'],
                    1550:       ['40px', '150px',   '250px',    '100%',             '100px',        0,                  '150px',    '115px'],
                    1650:       ['40px', '150px',   '250px',    '100%',             '100px',        '100px',            '200px',    '115px'],
                    1750:       ['40px', '150px',   '250px',    '100%',             '150px',        '150px',            '200px',    '115px'],
                    default:    ['40px', '150px',   '250px',    '100%',             '175px',        '175px',            '200px',    '115px'],
                }}
                data={tableData}
                hover
                border='row'
                className={'table--padding-m'}
                columnOverflowExceptions={[7]}
                columnTranslationExceptions={[1, 2]}
            />
            {paging}
        </div>
    )
}

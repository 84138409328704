import {useCallback, useContext, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import Icon from 'UI/App/Components/Icon/Icon';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import Button from 'UI/App/Components/Button/Button';
import Popup, {closePopup, openPopup} from 'UI/App/Components/Popup/Popup';
import Container from "UI/App/Components/Container/Container";

const statusOptions = [];
for (const status of ['Op voorraad', 'Bijna op', 'Op']) {
    statusOptions.push(<option key={status}>{status}</option>);
}
const query = stringify({
    populate: {
        material: {
            fields: ['id', 'calculationDescription']
        }
    },
    sort: ['material.calculationDescription:asc']
});

const materialsQuery = stringify({
    fields: ['id', 'calculationDescription', 'category'],
    sort: ['category:asc', 'calculationDescription:asc']
});

export default function StocksOverview() {
    const { authAxios } = useContext(FetchContext);
    const [searchParams] = useSearchParams();

    const [stocksTableData, setStocksTableData] = useState([]);
    const [stocksData, setStocksData] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);

    useEffect(() => {
        if (searchParams.has('add')) {
            getMaterialsList();
            openPopup('add-material-stock');

            // remove `add` from searchParams and reset them to clear the url.
            // causes getStockss to run twice
            // searchParams.delete('add');
            // setSearchParams(searchParams);
        }
    }, [searchParams]);

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery
    } = usePagination({
        searchSettings: {
            enabled: true,
            strapiFields: ['status', '[material][quotationName]']
        },
        htmlElements: [
            <span className='pagination__filter addItem' key='link--add'>
                <Button
                    onClick={() => {
                        getMaterialsList();
                        openPopup('add-material-stock');
                    }}
                    className='btn btn--add'
                >
                    Voorraad toevoegen{`\u00A0`}
                    <Icon name='plus' />
                </Button>
            </span>
        ]
    });

    const updateStock = useCallback(
        (target, stockId, stockKey) => {
            const toastFieldName = target.getAttribute('data-toast-field-name');

            authAxios
                .put(`/stocks/${stockId}?${query}`, {
                    data: {
                        [target.name]: target.value
                    }
                })
                .then(({ data }) => {
                    setStocksData((prev) => {
                        prev[stockKey][target.name] = target.value;
                        return [...prev];
                    });
                    toast.success(
                        <>
                            <b>{`${data.data.material.quotationName} voorraad`}</b>:{' '}
                            {toastFieldName} omgezet naar <i>{target.value}</i>
                        </>
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het bewerken van de voorraad.`);
                });
        },
        [authAxios]
    );

    const getStocks = useCallback(() => {
        authAxios
            .get(`/stocks?${query}&${filterQuery}`)
            .then(({ data }) => {
                setStocksData(data.data);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
            });
    }, [authAxios, filterQuery, setTotalPages, setTotalResults]);

    const setTableData = useCallback(() => {
        const tableData = [];

        // loop through all the records
        for (const [key, stock] of Object.entries(stocksData)) {
            tableData.push({
                attributes: {
                    id: `stock-${stock.id}`,
                    key: key
                },
                data: [
                    stock?.material?.calculationDescription ?? '-',
                    <select
                        name='status'
                        defaultValue={stock?.status ?? undefined}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={({ target }) => {
                            updateStock(target, stock.id, key);
                        }}
                        data-toast-field-name='status'
                    >
                        {statusOptions}
                    </select>,
                    <input
                        type='number'
                        name='currentQuantity'
                        defaultValue={stock?.currentQuantity}
                        step='1'
                        min='0'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onBlur={({ target }) => {
                            if (`${stock?.currentQuantity}` === `${target.value}`) return;
                            updateStock(target, stock.id, key);
                        }}
                        data-toast-field-name='hoeveelheid'
                    />,
                    <input
                        type='number'
                        name='minimumQuantity'
                        defaultValue={stock?.minimumQuantity}
                        step='1'
                        min='0'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onBlur={({ target }) => {
                            if (`${stock?.minimumQuantity}` === `${target.value}`) return;
                            updateStock(target, stock.id, key);
                        }}
                        data-toast-field-name='mimimum Hoeveelheid'
                    />
                ]
            });
        }

        setStocksTableData(tableData);
    }, [stocksData, updateStock]);

    const getMaterialsList = useCallback(() => {
        authAxios
            .get(`/logistics/materials/all?${materialsQuery}`)
            .then(({ data }) => {
                setMaterialOptions(() => {
                    const options = [];
                    for (const material of data ?? []) {
                        // find group by category
                        const categoryIndex = options.findIndex(
                            (group) => group?.label === material?.category
                        );

                        if (categoryIndex === -1) {
                            // create group + add current material
                            options.push({
                                label: material?.category,
                                options: [
                                    {
                                        label: material?.calculationDescription,
                                        value: material?.id
                                    }
                                ]
                            });
                        } else {
                            // add material to found group
                            options[categoryIndex].options.push({
                                key: material?.id,
                                label: material?.calculationDescription,
                                value: material?.id
                            });
                        }
                    }

                    return options;
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authAxios]);

    /* handle callback changes */
    useEffect(() => {
        // fetch stocks on page load & when the callback's dependencies change
        getStocks();
    }, [getStocks]);

    useEffect(() => {
        // set the table data on change on the `setTableData` function, which changes on `stockData` state
        setTableData();
    }, [setTableData]);

    return (
        <>
            <Container>
                {filtering}
                <Table
                    headers={['Materiaal SKU', 'status', 'Huidig hoeveelheid', 'Minimum']}
                    data={stocksTableData}
                />
                {paging}
            </Container>

            {/* add stock popup form */}
            <Popup title='Voorraad toevoegen' popupID='add-material-stock'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();

                        const data = {};
                        for (const input of e.target) {
                            if (!input.name || input.type === 'submit') continue;
                            data[input.name] = input.value;
                        }

                        const formData = new FormData();
                        formData.append('data', JSON.stringify(data));

                        authAxios
                            .post(`/stocks?${query}`, formData)
                            .then(() => {
                                // re-fetch current page again
                                getStocks();

                                closePopup();
                            })
                            .catch((error) => {
                                console.error(error);
                                toast.error(
                                    'De voorraad voor dit materiaal kon niet aangemaakt worden'
                                );
                            });
                    }}
                >
                    <div className='input-group'>
                        <label htmlFor='material-select' className='required'>
                            Materiaal
                        </label>
                        <Select
                            id='material-select'
                            name='material'
                            options={materialOptions}
                            required
                        />
                    </div>
                    <div className='input-group required'>
                        <label htmlFor='status-select' className='required'>
                            Status
                        </label>
                        <select id='status-select' name='status'>
                            {statusOptions}
                        </select>
                    </div>
                    <div className='input-group'>
                        <label htmlFor='current-quantity' className='required'>
                            Huidig hoeveelheid
                        </label>
                        <input
                            type='number'
                            id='current-quantity'
                            name='currentQuantity'
                            step='1'
                            min='0'
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='minimum-quantity' className='required'>
                            Minimum hoeveelheid
                        </label>
                        <input
                            type='number'
                            id='minimum-quantity'
                            name='minimumQuantity'
                            step='1'
                            min='0'
                        />
                    </div>
                    <Button type='submit'>Toevoegen</Button>
                </form>
            </Popup>
        </>
    );
}

import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Select from "react-select";
import {Controller} from "react-hook-form";

export default function JobTicketField({setValue, groupName, orderNumber, customerReference, calculationId, field, register, control}) {
  return (
    <div className="input-group">
      <InputField setValue={setValue} groupName={groupName} orderNumber={orderNumber} customerReference={customerReference} calculationId={calculationId} field={field} register={register} control={control}/>
    </div>
  )
}

function InputField({groupName, orderNumber, customerReference, calculationId, field, register, control, setValue}) {
  const getDefaultValueByName = (name) => {
    switch (name) {
      case 'ordernummer':
        return orderNumber;
      case 'klantreferentie':
        return customerReference;
      default:
        return '';
    }
  }

  const fieldProps = register(`${groupName}.${field.label.toLowerCase()}`, {
    value: getDefaultValueByName(field.label.toLowerCase()),
    required: false,
  })

  if (field.type === 'select') {
    return <SelectField field={field} fieldProps={fieldProps} control={control} setValue={setValue}/>
  } else if (field.type === 'select (more values)') {
    return <Select2DField field={field} fieldProps={fieldProps} control={control} setValue={setValue}/>
  } else if (field.type === 'material') {
    return <MaterialsField setValue={setValue} field={field} fieldProps={fieldProps} calculationId={calculationId} control={control}/>
  } else {
    return <SimpleField field={field} fieldProps={fieldProps}/>
  }
}

function SimpleField({field, fieldProps}) {
  return (
    <>
      <label>{field.label}</label>
      <input {...fieldProps} type={field.type}/>
    </>
  )
}

function SelectField({field, fieldProps, control, setValue}) {
  // Set first value selected if only one value
  if (field.options.length === 1) {
    setValue(fieldProps.name, field.options[0])
  }

  return (
    <>
      {field.options.length !== 1 ? <label>{field.label}</label> : <></>}
      <Controller
        control={control}
        name={fieldProps.name}
        render={(props) => (
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                display: field.options.length === 1 ? 'none' : 'flex'
              }),
            }}
            {...props.field}
            options={Object.entries(field?.options ?? {}).map(([label, value]) => ({label, value}))}
          />
        )}
      />
    </>
  )
}

function Select2DField({field, fieldProps, control, setValue}) {
  // Set first value selected if only one value
  if (field.options.length === 1) {
    setValue(fieldProps.name, field.options[0])
  }

  return (
    <>
      {field.options.length !== 1 ? <label>{field.label}</label> : <></>}
      <Controller
        control={control}
        name={fieldProps.name}
        render={(props) => (
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                display: field.options.length === 1 ? 'none' : 'flex'
              }),
            }}
            {...props.field}
            options={(field?.options ?? []).map((option) => ({ label: option.name, value: option }))}
          />
        )}
      />
    </>
  )
}

function MaterialsField({field, fieldProps, calculationId, control, setValue}) {
  const [fieldState, setFieldState] = useState({
    ...field,
    options: []
  });
  const {authAxios} = useContext(FetchContext);

  useEffect(() => {
    authAxios
      .get(`/calculations/${calculationId}/materials`)
      .then(({data}) => {
        setFieldState(fieldState => ({
          ...fieldState,
          options: data
            .filter(material => material.internalSKU)
            .filter(material => field.options?.category ? material.category?.toLowerCase() === field.options.category?.toLowerCase() : true)
            .sort((a, b) => a.internalSKU > b.internalSKU)
            .map(material => ({label: material.internalSKU, value: material.id, material: material}))
        }));
      })
      .catch(error => {
        toast.error('Er is iets misgegaan bij het ophalen van de materialen.')
        console.error(error);
      })
  }, [authAxios, calculationId]);

  // Set first value selected if only one value
  if (fieldState.options.length === 1) {
    setValue(fieldProps.name, fieldState.options[0])
  }

  return (
    <>
      {/* If one option hide label */}
      {fieldState.options.length !== 1 ? <label>{field.label}</label> : <></>}
      <Controller
        control={control}
        name={fieldProps.name}
        render={(props) => (
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                display: fieldState.options.length === 1 ? 'none' : 'flex'
              }),
            }}
            {...props.field}
            options={fieldState.options}
          />
        )}
      />
    </>
  )
}
